import React from 'react'

export function ErrorIcon(){
  return(
    <svg id="error-icon" width="280" height="280" viewBox="0 0 280 280" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M84.5562 73.886C84.5562 73.886 114.58 96.67 160.224 54.068C200.76 16.2343 233.859 75.9275 234.09 104.514C234.39 141.544 193.554 171.173 213.372 195.495C233.189 219.817 174.07 259.981 142.208 225.222C102.572 181.983 91.8345 217.115 69.2424 217.115C53.0278 217.115 19.7376 176.825 42.2181 146.851C61.1352 121.629 50.8181 113.258 45.822 104.514C38.6149 91.9022 55.7303 57.6715 84.5562 73.886Z" fill="#DEE9FF"/>
      <path d="M205.092 124.235C206.706 124.835 208.469 124.875 210.147 124.489C217.177 122.876 241.35 118.603 237.15 137.335C236.235 141.051 222.078 161.865 242.239 169.462C243.717 170.026 245.279 170.334 246.86 170.372C253.068 170.5 265.369 169.034 270.517 163.794C276.737 157.463 269.942 160.465 269.942 160.465C269.942 160.465 246.298 170.783 237.946 159.836C237.017 158.618 236.425 156.849 236.256 155.326C235.962 152.686 237.134 149.197 238.674 146.159C240.759 142.048 250.766 123.555 229.038 118.168C224.033 116.976 216.763 115.906 207.39 119.852C201.922 122.154 203.133 123.507 205.092 124.235Z" fill="#BDD6F9"/>
      <path d="M209.93 117.331L203.637 119.687C201.465 120.5 200.363 122.92 201.176 125.092L201.488 125.926C202.301 128.098 204.721 129.2 206.893 128.387L213.187 126.032C215.359 125.219 216.461 122.799 215.648 120.626L215.336 119.793C214.523 117.62 212.103 116.518 209.93 117.331Z" fill="#0E84E5"/>
      <path d="M171.3 124.147L150.546 131.915C149.459 132.321 148.908 133.531 149.315 134.617L149.397 134.836C149.803 135.922 151.013 136.473 152.1 136.067L172.854 128.299C173.941 127.892 174.492 126.682 174.085 125.596L174.003 125.377C173.597 124.291 172.387 123.74 171.3 124.147Z" fill="white"/>
      <path d="M177.093 139.624L156.338 147.391C155.252 147.798 154.701 149.008 155.108 150.094L155.189 150.313C155.596 151.399 156.806 151.95 157.892 151.543L178.647 143.776C179.733 143.369 180.284 142.159 179.878 141.073L179.796 140.854C179.39 139.768 178.179 139.217 177.093 139.624Z" fill="white"/>
      <path d="M196.255 113.585L179.434 119.88C177.261 120.693 176.159 123.114 176.972 125.286L182.716 140.631C183.529 142.803 185.949 143.905 188.121 143.092L204.943 136.797C207.115 135.984 208.217 133.564 207.404 131.391L201.661 116.046C200.848 113.874 198.428 112.772 196.255 113.585Z" fill="#5895F9"/>
      <path d="M181.172 117.61L164.35 123.906C162.178 124.719 161.076 127.139 161.889 129.312L168.741 147.619C169.554 149.791 171.974 150.893 174.146 150.08L190.968 143.785C193.14 142.972 194.242 140.552 193.429 138.379L186.577 120.072C185.764 117.899 183.344 116.797 181.172 117.61Z" fill="#699CFF"/>
      <path d="M183.805 113.895L183.673 113.944C182.225 114.486 181.49 116.1 182.032 117.548L191.631 143.195C192.173 144.643 193.786 145.378 195.234 144.836L195.366 144.786C196.815 144.244 197.549 142.631 197.007 141.183L187.409 115.536C186.867 114.088 185.253 113.353 183.805 113.895Z" fill="#208AF2"/>
      <path d="M12.2816 130.292C12.2816 130.292 46.0647 120.798 41.16 142.672C40.3082 146.133 28.0471 163.953 42.6519 172.509C45.7879 174.346 49.4069 175.16 53.0412 175.141C59.5572 175.107 69.8871 173.853 74.5277 169.13C80.747 162.799 73.9526 165.801 73.9526 165.801C73.9526 165.801 54.5504 173.782 44.5687 167.42C41.1604 165.248 39.6155 161.042 40.541 157.107C41.0141 155.153 41.7344 153.267 42.6847 151.495C44.8555 147.429 54.7768 128.891 33.0488 123.504C28.0435 122.312 20.7737 121.242 11.4 125.188C2.02631 129.135 12.2816 130.292 12.2816 130.292Z" fill="#BDD6F9"/>
      <path d="M79.0704 160.906L72.7772 163.262C70.6047 164.075 69.5027 166.495 70.3158 168.668L70.6278 169.501C71.4408 171.674 73.861 172.776 76.0335 171.962L82.3267 169.607C84.4992 168.794 85.6011 166.374 84.7881 164.201L84.4761 163.368C83.6631 161.195 81.2428 160.093 79.0704 160.906Z" fill="#0E84E5"/>
      <path d="M89.7031 150.552L80.3762 154.043C78.2037 154.856 77.1017 157.276 77.9148 159.449L82.4122 171.465C83.2253 173.638 85.6455 174.74 87.8179 173.927L97.1448 170.436C99.3173 169.623 100.419 167.203 99.6062 165.03L95.1088 153.014C94.2957 150.841 91.8755 149.739 89.7031 150.552Z" fill="#5895F9"/>
      <path d="M105.23 141.3L91.3212 146.506C89.1488 147.319 88.0468 149.739 88.8599 151.912L95.7116 170.219C96.5247 172.392 98.9449 173.494 101.117 172.681L115.026 167.475C117.199 166.662 118.301 164.242 117.488 162.069L110.636 143.762C109.823 141.589 107.403 140.487 105.23 141.3Z" fill="#699CFF"/>
      <path d="M108.177 137.509L108.045 137.558C106.596 138.1 105.862 139.714 106.404 141.162L116.002 166.809C116.544 168.257 118.158 168.992 119.606 168.45L119.738 168.4C121.186 167.858 121.921 166.245 121.379 164.796L111.78 139.15C111.238 137.702 109.625 136.967 108.177 137.509Z" fill="#208AF2"/>
      <path d="M143.694 121.17C143.193 121.114 142.733 120.867 142.411 120.479C142.09 120.091 141.931 119.594 141.968 119.091L143.006 100.135C143.068 99.0027 144.024 98.1424 145.109 98.2425L146.621 98.3825C147.706 98.4826 148.483 99.5025 148.331 100.627L145.781 119.443C145.723 119.944 145.473 120.403 145.084 120.725C144.695 121.047 144.197 121.206 143.694 121.17Z" fill="#418DF9"/>
      <path d="M134.733 122.804C134.577 122.85 134.414 122.865 134.252 122.848C134.091 122.83 133.934 122.781 133.792 122.703C133.649 122.626 133.524 122.521 133.422 122.394C133.32 122.268 133.244 122.122 133.198 121.967L129.492 110.175C129.384 109.84 129.414 109.475 129.574 109.162C129.735 108.848 130.013 108.611 130.349 108.503L131.308 108.213C131.473 108.165 131.646 108.152 131.816 108.174C131.986 108.196 132.149 108.253 132.296 108.342C132.443 108.43 132.57 108.548 132.669 108.688C132.768 108.828 132.837 108.987 132.871 109.155L135.618 121.237C135.695 121.561 135.646 121.903 135.482 122.194C135.318 122.484 135.051 122.702 134.733 122.804Z" fill="#418DF9"/>
      <path d="M129.732 129.604C129.068 130.427 127.732 130.417 126.704 129.582L108.453 114.753C107.362 113.867 107.041 112.401 107.745 111.529L108.727 110.313C109.432 109.44 110.878 109.512 111.91 110.47L129.18 126.516C130.153 127.419 130.396 128.782 129.732 129.604Z" fill="#418DF9"/>
      <path d="M138.169 167.906C138.639 168.088 139.02 168.445 139.232 168.902C139.445 169.359 139.471 169.881 139.307 170.357L133.462 188.419C133.113 189.498 131.969 190.085 130.945 189.711L129.518 189.19C128.495 188.816 128.004 187.631 128.438 186.584L135.709 169.043C135.894 168.573 136.252 168.193 136.711 167.981C137.169 167.769 137.691 167.742 138.169 167.906Z" fill="#418DF9"/>
      <path d="M147.25 168.615C147.412 168.61 147.574 168.638 147.726 168.696C147.877 168.754 148.016 168.841 148.134 168.953C148.252 169.064 148.347 169.198 148.413 169.346C148.479 169.494 148.516 169.654 148.52 169.817L149.092 182.164C149.11 182.516 148.989 182.86 148.753 183.122C148.518 183.385 148.188 183.543 147.837 183.562L146.835 183.597C146.663 183.601 146.493 183.57 146.334 183.505C146.175 183.44 146.032 183.343 145.912 183.22C145.793 183.097 145.7 182.951 145.64 182.79C145.581 182.629 145.555 182.458 145.564 182.287L145.994 169.905C146.003 169.571 146.138 169.253 146.37 169.014C146.603 168.775 146.917 168.633 147.25 168.615Z" fill="#418DF9"/>
      <path d="M153.822 163.317C154.674 162.691 155.963 163.042 156.743 164.112L170.603 183.11C171.43 184.245 171.367 185.744 170.463 186.408L169.203 187.333C168.299 187.997 166.918 187.558 166.165 186.368L153.565 166.444C152.857 165.323 152.97 163.943 153.822 163.317Z" fill="#418DF9"/>
    </svg>
  )
}