const languages = [
  { value: "Português", label: "Português Brasil"},
  { value: "Inglês", label: "Inglês"},
  { value: "Francês", label: "Francês"},
  { value: "Espanhol", label: "Espanhol"},
  { value: "Mandarim", label: "Mandarim"},
  { value: "Português Portugal", label: "Português de Portugal"},
  { value: "Outra", label: "Outra"},
]

export default languages;