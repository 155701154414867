const subjects = [
  {value:"Ciências Agrárias", label: "Ciências Agrárias"},
  {value:"Ciências Biológicas", label: "Ciências Biológicas"},
  {value:"Ciências Exatas e da Terra", label: "Ciências Exatas e da Terra"},
  {value:"Ciências Humanas", label: "Ciências Humanas"},
  {value:"Ciências da Saúde", label: "Ciências da Saúde"},
  {value:"Ciências Sociais Aplicadas", label: "Ciências Sociais Aplicadas"},
  {value:"Engenharias Lingüística, Letras e Artes", label: "Engenharias Lingüística, Letras e Artes"},
  {value:"Multidisciplinar", label: "Multidisciplinar"},
]

export default subjects;