const resourceTypeOptions = [
  {value:"Video", label: "Video"},
  {value:"Livro", label: "Livro"},
  {value:"Podcast", label: "Podcast"},
  {value:"Artigo", label: "Artigo"},
  {value:"Imagem", label: "Imagem"},
  {value:"Jogo", label: "Jogo"},
  {value:"Filme", label: "Filme"},
  {value:"Música", label: "Música"},
  {value:"Software", label: "Software"},
  {value:"Slide", label: "Slide"},
  {value:"Blog", label: "Blog"},
  {value:"Website", label: "Website"},
]

export default resourceTypeOptions;